.qmenu-tabs ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.qmenu-tabs li {
    position: relative;
    display: inline-block;
}

.qmenu-tabs .nav-link:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-top: 24px solid white;
    border-left: 10px solid;
    border-left-color: #E0E8EF;
    border-top-right-radius: 2px;
}

.qmenu-tabs .nav-link {
    background-color: #dfe8ef;
    color: #365064;
    display: inline-block;
    padding: 4px 10px 0 3px;
    font-size: 13px;
}

.qmenu-tabs .nav-link.active:before {
    border-left-color: #1F6FA3;
}

.qmenu-tabs .nav-link.active {
    background-color: #1F6FA3;
    color: #ffffff !important;
}

#qmenu-device {
    padding: 2px 3px 3px 0;
    font-size: 14px;
    float: none;
    margin: 0 1px;
    border: 1px solid #ddd;
    border-radius: 3px 3px 3px 3px;
}

#qmenu-driver {
    padding: 0 3px 0 3px;
    margin-left: 2px;
    border-left: 2px solid #000;
}

#qmenu-blink {
    background: #337ab7;
    padding: 5px 7px;
    border-radius: 3px 0 0 3px;
    margin-right: 3px;
    -webkit-animation: 1s linear infinite blink_effect; /* for Safari 4.0 - 8.0 */
    animation: 1s linear infinite blink_effect;
}

@keyframes blink_effect {
    0% {
        visibility: hidden;
    }
    50% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}


.home-daily {
    position: relative;
    padding-left: 2px;
}

.home-daily .filters .change-day span {
    cursor: pointer;
}

.home-daily .filters .filter-1 {
    float: left;
    margin: 0 10px;
    padding-top: 10px;
    text-align: center;
}

.home-daily .filters .filter-2 {
    float: left;
    margin-right: 10px;
}

.home-daily .filters .filter-2 input {
    margin-bottom: 5px;
    width: 140px;
}

.home-daily .filters .filter-3 {
    float: left;
    padding-top: 12px;
}

.home-daily .filters .filter-3 .report-icon {
    float: left;
    text-align: center;
    margin-right: 5px;
}

.home-daily .filters .filter-4 {
    width: 214px;
    float: left;
    margin-right: 15px;
}

.home-daily .filters .filter-4 .form-control {
    padding-left: 2px;
}

.home-daily .filters .filter-4 .input-group-btn {
    width: 25px;
    text-align: center;
}

.home-daily .filters .filter-4 .select-device .form-control {
    padding: 7px 6px;
    font-size: 12px;
    border-radius: 2px;
    height: 31px;
    color: #555;
}

.home-daily .filters .reports-icon-big {
    width: 105px;
    min-height: 62px;
}

.home-daily table {
    font-size: 13px;
}

.home-daily table > tbody > tr > td {
    text-align: center;
    padding: 4px;
    vertical-align: middle;
}

.home-daily .ui-select-container .home-monthly .ui-select-container {
    width: 108%;
    min-width: 175px;
}

.home-daily .ui-select-match-text .home-monthly .ui-select-match-text {
    width: 160px;
    overflow: hidden;
}

.home-daily .ui-select-container .dropdown-menu .home-monthly .ui-select-container .dropdown-menu {
    left: 25px;
}

.home-daily table>tbody>tr.active>td{
    background: #F5F5FC;
    color: #333;
}

.home-daily table>tbody>tr.info>td{
    background: #d9edf7;
    color: #333;
}

.home-monthly {
    position: relative;
    padding-left: 2px;
}

.home-monthly .filters .change-day span {
    cursor: pointer;
}

.home-monthly .filters .filter-1 {
    float: left;
    margin: 0 10px;
    padding-top: 15px;
    text-align: center;
}

.home-monthly .filters .filter-2 {
    float: left;
    margin-right: 10px;
    width: 140px
}

.home-monthly .filters .filter-3 {
    float: left;
    margin-right: 10px;
    padding-top: 18px;
}

.home-monthly .filters .filter-3 .report-icon {
    float: left;
    text-align: center;
    margin-right: 5px;
}

.home-monthly .filters .filter-4 {
    width: 220px;
    float: left;
    margin-right: 15px;
}

.home-monthly .filters .filter-4 .form-control {
    padding-left: 2px;
}

.home-monthly .filters .filter-4 .input-group-btn {
    width: 25px;
    text-align: center;
}

.home-monthly .filters .filter-4 .select-device .form-control {
    padding: 7px 6px;
    font-size: 12px;
    border-radius: 2px;
    height: 31px;
    color: #555;
}

.home-monthly .filters .reports-icon-big {
    width: 110px;
    min-height: 62px;
}

.home-monthly table {
    font-size: 13px;
}

.report-detail-icon {
    cursor: pointer;
}

.home-monthly table > tbody > tr > td {
    text-align: center;
    padding: 4px;
    vertical-align: middle;
}

.home-monthly table>tbody>tr.active>td{
    background: #F5F5FC;
    color: #333;
}

.home-monthly table>tbody>tr.info>td{
    background: #d9edf7;
    color: #333;
}