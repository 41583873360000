.standart-group-name {
    background-color: #abcf38;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
}

.standart-big-group-name {
    background-color: #abcf38;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
}

.klein-group-name {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    padding-top: 2px;
    border-top: 1px solid #21b04a;
    border-bottom: 1px solid #21b04a;
    background: rgba(242, 244, 245, 1);
    background: -moz-linear-gradient(top, rgba(242, 244, 245, 1) 0%, rgba(242, 242, 242, 1) 53%, rgba(208, 214, 219, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(242, 244, 245, 1) 0%, rgba(242, 242, 242, 1) 53%, rgba(208, 214, 219, 1) 100%);
    background: -o-linear-gradient(top, rgba(242, 244, 245, 1) 0%, rgba(242, 242, 242, 1) 53%, rgba(208, 214, 219, 1) 100%);
    background: -ms-linear-gradient(top, rgba(242, 244, 245, 1) 0%, rgba(242, 242, 242, 1) 53%, rgba(208, 214, 219, 1) 100%);
    background: linear-gradient(to bottom, rgba(242, 244, 245, 1) 0%, rgba(242, 242, 242, 1) 53%, rgba(208, 214, 219, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f2f4f5', endColorstr='#d0d6db', GradientType=0);
    margin: 4px 0 4px 0;
}

.detailed-group-name {
    background-color: #48B657;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    color: #fff;
    padding-top: 2px;
    border-radius: 2px;
}

.mini-group-name {
    background: #e6e6e6;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    border-radius: 4px;
    padding-top: 2px;
}

.big-group-name {
    background-color: rgba(16, 16, 16, 0.6);
    text-align: center;
    font-size: 14px;
    color: #fff;
    padding: 2px 0;
    border-radius: 1px;
}
