.device-list-toolbar {
    margin-bottom: 3px;
}

#sort-devices {
    color: #fff;
    background: #abcf39;
    border-color: #9aba33;
    width: 100%;
}

#list-types {
    color: #fff;
    background: #58c2f3;
    border-color: #4fafdb;
    transition: none;
    width: 100%;

}

#options {
    color: #fff;
    background: #b1b0af;
    border-color: #9f9e9e;
    width: 100%;
}

.device-list-toolbar .btn-group .dropdown{
    width: 33.33333%;
    max-width: 33.33333%;
    margin-left: 0;
}

.sort-dropdown-item {
    position: relative;
}

.sort-dropdown-item > span:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
}

#device_search {
    padding: 5px;
    background-color: #EDEAE4;
    margin-top: 5px;
    position: relative;
}

.sort-dropdown-item:hover .sort-menu{
    display: block;
}

.sort-dropdown-item .sort-menu {
    display:none;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 1000;
    min-width: 140px;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    color: #303438;
}

.sort-dropdown-item .sort-menu ul {
    list-style: none;
    padding: 0 0 0 0;
}

.sort-dropdown-item .sort-menu li {
    padding: 5px 5px;
}

.sort-dropdown-item .sort-menu li:hover {
    background-color: #b1b0af;
}

.complete-row {
    padding: 5px;
    color: #000000;
    margin-bottom: 4px;

}

.complete-selected-row, .complete-row:hover {
    background-color: #09d;
    color: #ffffff;
    cursor: pointer;
}