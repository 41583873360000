/* Standart List Styles */
.standart {
    width: 131px;
    display: inline-table;
    margin: 1px 2px 0px 2px;
    position: relative;
}

.pointer_event {
    pointer-events: none;
}

.tooltip {
    width: 120px;
    z-index: 99999999;
}

.standart a {
    color: #1267a1;
}

.standart .header {
    background: url("./images/background.jpg") no-repeat;
    width: 131px;
    height: 23px;
    font-size: 12px;
}

.standart.active .header {
    background: url("./images/background_active.gif") no-repeat;
}

.standart .name {
    display: block;
    width: 88px;
    padding-top: 4px;
    font-size: 12px;
    white-space: nowrap;
    text-decoration: underline;
}

.standart .header-left {
    float: left;
    margin-right: 5px;
}

.standart .header-left a {
    width: 13px;
    height: 22px;
    display: block;
}

.standart .header-right {
    width: 38px;
    float: right;
    margin-right: 2px;
    font-size: 10px;
    text-align: right;
}

.standart .mileage, .standart .ign {
    height: 9px;
}

.standart .map-link {
    float: right;
}
.standart .map-link2 {
    float: right;
}
.standart .map-link img{
    width: 13px;
}
.standart .map-link2 img{
    width: 13px;
}

.standart .body {
    background-color: #ddd;
}

.standart .body-left {
    float: left;
    margin-top: -1px;
}

.standart .body-left a {
    display: block;
    height: 15px;
    background-color: #ffffff;
    font-size: 11px;
    padding: 0 4px;
    color: #000000;
    border-bottom: 1px solid #000000;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    text-align: center;
}

.standart a.device-service-alert {
    background: #FF0000;
    color: #fff;
}

.standart .body-car {
    float: left;
    padding: 1px 3px 3px 3px;
    height: 27px;
    text-align: center;
    border-right: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
}

.standart .body-car a {
    height: 25px;
    overflow: hidden;
    display: block;
}

.standart .body-car img {
    max-width: 25px;
}

.standart .body-middle {
    float: left;
    height: 27px;
    width: 78px;
    border-bottom: 1px solid #ffffff;
    font-size: 12px;
}

.standart .speed {
    float: left;
    display: block;
    height: 10px;
    font-size: 11px;
    text-decoration: underline;
}

.standart .chart {
    float: left;
    margin-right: 1px;
}

.standart .inputs {
    float: left;
    width: 33px;
}

.standart .inputs a {
    width: 29%;
    display: inline-block;
}

.standart .body-live {
    float: right;
    display: block;
    height: 10px;
    font-size: 11px;
    text-decoration: underline;
    margin-top: -2px;
}

.standart .body-input-icon {
    width: 9px;
    height: 9px;
}

.standart .body-driver {
    float: left;
    padding: 2px 3px 3px 3px;
    padding-bottom: 0;
    height: 16px;
    font-size: 11px;
}

.standart .body-driver img {
    float: left;
}
.standart .driver_trip_typ {
    float: left;
    padding: 1px;
    font-size: 9px;
    line-height: 9px;
    margin: 4px 0px 0px 0px;
}

.standart .body-driver a {
    white-space: nowrap;
    max-width: 80px;
    overflow: hidden;
    float: left;
}

.mini-device-body-driver {
    float: left;
    height: 16px;
    font-size: 11px;
}

.standart .footer {
    background-color: #008080;
    color: #ffffff;
    font-size: 11px;
}

.standart .footer ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.standart .footer ul li {
    float: left;
    width: 29px;
    border-right: 1px solid #ffffff;
    text-align: center;
    padding-top: 0;
    font-size: 9px;
}

.standart .footer ul li a {
    color: #ffffff;
}

.standart .footer-small {
    width: 12px !important;
    font-size: 10px !important;
    padding-top: 0px !important;
}

.standart .footer-bot {
    margin-bottom: 5px;
    text-align: center;
    background-color: #ffffff;
    color: #101010;
    line-height: 10px;
    font-size: 11px;
    padding-top: 3px;
}

.standart .address-left {
    float: left;
    width: 80%;
    padding: 0 3px;
}

.standart .battery {
    float: left;
    width: 20%;
}

.standart .body-lock {
    float: right;
    width: 29px;
    height: 16px;
    margin-top: -2px;
}

.standart .updating-device {
    position: absolute;
    top: 38%;
    left: 40%;
    display: none;
    z-index: 99999;
}
