.device-groups {
    padding-left: 10px;
}

.device-groups table thead tr th {
    text-align: center;
}

.device-groups .head {
    font-size: 18px;
    margin-bottom: 0;
}

.device-groups .group-filter {
    min-width: 100px;
    background-color: #f9f8f6;
    border-radius: 3px;
    margin: 0 0 15px 15px;
    border: 1px solid #e3e3e3;
    padding: 10px;
}

.device-groups .update-group-name {
    cursor: pointer;
    padding: 8px;
    top: -2px;
}

.device-groups .update-group-name:hover {
    background: #eee;
    border-radius: 5px;
}

.device-groups .selectbox {
    height: 15em;
    border: 1px solid #eee;
    border-radius: 4px;
    overflow: auto;
    padding-top: 6px;
    padding-left: 10px;
}

.device-groups .selectbox label {
    display: block;
    font-weight: normal;
    cursor: pointer;
}

.device-groups .all-groups > table {
    border: 1px solid #ddd;
}

.device-groups .all-groups .glyphicon {
    cursor: pointer;
}

.device-groups .group-filter {
    min-width: 250px;
    background-color: #f9f8f6;
    border-radius: 3px;
    margin: 0 0 15px 15px;
    border: 1px solid #e3e3e3;
    padding: 10px;
}

.device-groups .group-detail {
    display: flex;
    float: left;
    min-width: 600px;
}

@media (min-width: 992px){
    .col-md-2 {
        width: 16.66666667%;
    }
}

@media (min-width: 768px){
    .col-md-2 {
        float: left
    }
}

@media (min-width: 1200px){
    .col-lg-6 {
        width: 50%;
    }
}

@media (min-width: 992px){
    .col-md-8 {
        width: 66.66666667%;
    }
}

.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.no-padding-left {
    padding-left: 0;
}