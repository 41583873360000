.icon {
    position: relative;
    cursor: pointer;
}

.icon:hover .icon-menu{
    display: block;
}

.icon .icon-menu {
    display:none;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 1000;
    min-width: 280px;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    color: #303438;
}

.icon .icon-menu img{
    margin-left: 1px;
}

.icon .icon-menu ul {
    list-style: none;
    padding: 0 0 0 0;
}

.icon .icon-menu li {
    padding: 5px 5px;
}

.table-center {
    text-align: center;
}
