.dropdown-submenu {
    position: relative;
}

.group-box {
    margin-bottom: 5px;
}

.dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu > .dropdown-menu.left {
    right: 100%;
    left: -140%;
}

.dropdown-submenu:hover > .dropdown-menu {
    display: block;
}

.dropdown-submenu > a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
    border-left-color: #fff;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}


.grossansicht {
    width: 100%;
    padding-bottom: 7px;
}

.grossansicht-groups {
    float: left;
}

.grossansicht-menu {
    float: left;
    margin-right: 5px;
}

.grossansicht-menu a {
    font-size: 13px;
}

.grossansicht .gros-plate {
    width: 100%;
    display: inline-block;
    padding: 0 3px 0 3px;
    border-bottom: 1px solid #e5e5e5;
}

.grossansicht .gros-plate > div {
    float: left;
}

.grossansicht .btn {
    border-radius: 2px;
    margin-right: 1px;
}

.grossansicht .group-name {
}

.grossansicht .car-icon {
    width: 36px;
    text-align: center;
    padding: 5px 3px 0 0;
}

.grossansicht .gros-plate .mileage {
    width: 40px;
    text-align: center;
}

.grossansicht .gros-plate .pull-right{
    float: right;
}

.grossansicht .gros-plate .ignition{
    align-items: center;
}

.grossansicht .gros-plate .device-live a,
.grossansicht .gros-plate .mileage a {
    font-size: 12px;
}

.grossansicht .dropdown {
    position: relative;
    display: inline-block;
}

.grossansicht .dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    z-index: 999999;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    margin: 0;
}

.grossansicht .gros-plate a {
    font-size: 13px;
    color: #1267a1;
    text-decoration: underline;
    display: block;
}

.grossansicht .gros-plate a:hover {
    color: #0F507D;
}

.grossansicht .dropdown:hover .dropdown-content {
    display: block;
}

.dropdown.open {
    z-index: 100010
}