.content {
    width: 80%;
}

.rentForm h4 {
    margin-top: 80px
}

.rentForm h4:first-child {
    margin-top: 10px
}

.mapBottom .col-md-4 {
    min-width: 300px;
    overflow: hidden;
}

.content {
    margin-bottom: 50px
}

.mapBottom .p2 td {
    padding: 2px
}

#rentaCarForm .form-control {
    width: 200px;
}

.gaugae_bg_green {
    display: none
}

.fuel-canvas canvas {
    -ms-transform: rotate(-4deg); /* IE 9 */
    -webkit-transform: rotate(-4deg); /* Chrome, Safari, Opera */
    transform: rotate(-4deg);
}

.content .react-datepicker-wrapper .react-datepicker__input-container input {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;}

    .content tbody > tr > td {
        text-align: center;
        padding: .25rem;
    }

    .ngdialog-content {
        width: 800px !important;
    }