.mini {
    position: relative;
    display: inline-table;
    width: 130px;
    height: 24px;
    background: url("./images/plate_bg_mini.jpg") no-repeat;
    margin: 4px 6px 4px 4px;
    line-height: 4px;
}

.mini a {
    display: block;
    width: 100%;
    height: 100%;
}

.mini .left {
    float: left;
    width: 14px;
    height: 23px;
}

.mini .right {
    float: left;
    line-height: 5px;
}

.mini .top {
    height: 15px;
    margin-top: 5px;
    width: 107px;
}

.mini .bottom {
    margin-top: -5px;
}

.mini .name {
    float: left;
    font-size: 10px;
    min-width: 51px;
    margin-right: 2px;
}

.mini .driver {
    float: left;
    font-size: 10px;
    min-width: 51px;
    margin-right: 2px;
}

.mini .mileage {
    float: right;
    font-size: 9px;
    text-align: right;
}

.mini .ign {
    float: right;
    margin-top: -2px;
}

.mini .updating-device {
    position: absolute;
    top: 3px;
    left: 57px;
    display: none;
    z-index: 99999;
}

.mini .start, .mini .stop {
    float: left;
    font-size: 8px;
}

.mini .color-route {
    float: right;
}

.mini .color-route a {
    font-weight: bold;
    font-size: 10px;
    color: #1267a1;
}
