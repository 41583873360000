.tab-content {
    font-size: 13px;
}

.tab-content .form-control {
    font-size: 13px;
}

.tab-content > .active {
    border: 1px solid #dee2e6;
    border-top: none;
    padding: 10px;
}

.image_picker {
    height: 450px;
    overflow-y: auto;
}

.image_picker .responsive {
    padding: 5px;
    margin: 0;
}

.image_picker .responsive .thumbnail {
    object-fit: none !important;
    width: 44px !important;
    height: 44px !important;
}

.image_picker .selected .checked .icon {
    width: 25px !important;
    height: 25px !important;
}

.image_picker .responsive .selected {
    background-color: #c0c3c4;
    border: 4px solid #606060;
}

.react-datepicker__input-container > input {
    text-align: left;
}

.service-border-box {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #ddd;
}

.service-divider {
    border-top: 1px solid #ccc;
    margin: 5px 0 5px 0;
    padding-top: 5px;
}

.service-title {
    background-color: #d9edf7;
    color: #31708f;
    padding: 5px;
    margin-bottom: 10px;
}
.service-alert {
    background-color: #f8d7da;
}