.loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    z-index: 999;
    filter: alpha(opacity=60);
    -moz-opacity: 0.8;
    opacity: 0.8;
}