#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 292px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: absolute;
  left: 0;
  width: 0;
  height: 100%;
  margin-right: -292px;
  background: #ffffff;
  padding-right: 1px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 292px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 0;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: 0;
}

.nopadding {
  padding: 0;
}

.table td, .table th {
  padding: 0.25rem;
  text-align: center;
}

.table thead th.success {
  background-color: #dff0d8;
}

.table thead th.warning {
  background-color: #fcf8e3;
}

.table thead th.info {
  background-color: #d9edf7;
}

.container-fluid {
  font-size: 15px !important;
}

.pagination {
  max-width: 40rem;
  overflow-x: auto;
}

@media(min-width:768px) {
  #wrapper {
    padding-left: 0;
  }
  #wrapper {
    padding-left: 292px;
  }
  #sidebar-wrapper {
    width: 0;
  }
  #wrapper #sidebar-wrapper {
    width: 292px;
  }
  #page-content-wrapper {
    padding: 0;
    position: relative;
  }
  #wrapper #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}