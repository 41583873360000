.react-datepicker__input-container > input {
    text-align: center;
    font-size: 12px;
    color:#495057;
    border: 1px solid #ccc;
    border-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    height: 30px;
}
.react-datepicker__input-container {
    width: auto;
}
.react-datepicker-wrapper {
    display: inline-block !important;
}

.react-datepicker-wrapper-full-width {
    display: block !important;
    width: 100% !important;
}

.react-datepicker-wrapper-full-width .react-datepicker__input-container input {
    height: 38px !important;
}

.react-datepicker-wrapper-full-width .react-datepicker__input-container {
    width: 100%;
}

.date-filter-container > * {
    display: inline-block;
}