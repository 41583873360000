.klein {
    position: relative;
    width: 261px;
    min-height: 57px;
    max-height: 94px;
    margin: 2px auto;
    background: url('./images/background.png') no-repeat;
    background-size: 98.5%;
}

.klein a {
    color: #212529;
}

.klein-left {
    width: 23px;
    height: 50px;
    display: block;
    z-index: 1001;
    float: left;
}

.klein-right {
    float: left;
    width: 230px;
}

.klein-top > div, .klein-bottom > div {
    float: left;
    font-size: 11px;
}

.klein-ign {
    position: absolute;
    top: 13px;
    left: 121px;
}

.klein-icon {
    margin: 4px 0 0 3px;
}

.klein-icon a {
    height: 22px;
    display: block;
    overflow: hidden;
}

.klein-name {
    width: 63px;
    margin: 9px 0 0 2px;
}

.klein-mileage {
    margin: 5px 9px 2px 2px;
    min-width: 42px;
    text-align: center;
}

.klein-speed {
    margin: 9px 5px 0 0;
    min-width: 45px;
    text-align: center
}

.klein-live {
    margin-top: 11px;
}

.klein-route {
    font-size: 12px;
    font-weight: bold;
    margin: 4px 10px 0px 11px;
}

.klein-driver {
    width: 92px;
    background: url('../../Assets/images/man.png') no-repeat;
    margin: 4px 3px 0 6px;
    padding: 2px 0 0 18px;
}

.klein-tools {
    text-align: center;
    margin: 5px 1px 0 0;
}

.klein-inputs {
    width: 38px;
    margin: 4px 5px 0 0;
}

.klein-inputs a {
    display: inline-block;
    width: 25%;
}

.klein-address {
    font-size: 12px;
    text-align: center;
    padding-top: 3px;
    margin: 0;
    overflow: hidden;
    height: 21px;
}

.klein-battery {
    text-align: center;
    margin-bottom: 5px;
    display: none;
}

.klein-updating-device {
    position: absolute;
    top: 7px;
    left: 120px;
    display: none;
    z-index: 999;
}

.klein-device-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    filter: alpha(opacity=60);
    -moz-opacity: 0.6;
    opacity: 0.6;
    z-index: 999;
    display: none;
    cursor: pointer;
}