.bg-horizont {
    background-color: #abcf38;
    color: #ffffff;
    font-size: 13px;
    font-weight: bold;
}
.round-button {
    width: 30px;
    margin: 10px 10px 0px 5px;
}
.round-button-circle {
    width: 25px;
    height: 0;
    padding-bottom: 67%;
    border-radius: 50%;
    border: 2px solid #cfdcec;
    overflow: hidden;
    background: #A2A2A2;
    box-shadow: 0 0 3px grey;
}
.round-button a {
    display: block;
    float: left;
    width: 100%;
    padding-top: 13%;
    padding-bottom: 50%;
    line-height: 1em;
    margin-top: -0.5em;
    text-align: left;
    color: #e2eaf3;
    font-family: Verdana;
    font-size: 1em;
    font-weight: bold;
    text-decoration: none;
    padding-left: 5px;
    padding-right: 2px;
    margin: 0;
}
.fa-signal, .fa-star {
    font-size: 20px !important;
    color: #333;
}
.fa-star {
    color: #FFFFFF;
    margin-left: 10px;
    margin-right: 10px;
}
.fa-phone {
    font-size: 40px !important;
}
.fa-user {
    font-size: 16px !important;
    padding-right: 5px;
}
.car-icon {
    padding-top: 10px;
}
.logo {
    padding-left: 5px;
    height: 32px;
}
.phone {
    padding-left: 20px;
}
.phone .phone-title {
    color: #cc6600;
    font-size: 13px;
}
.phone .phone-number {
    font-size: 15px;
}
.navbar {
    padding: 0 1rem;
    z-index: 99999;
}
.navbar-light .navbar-nav .nav-link {
    padding-top: 11px;
    border-right: 1px solid #ffffff;
    color: #ffffff;
}
.navbar-light .navbar-nav .no-border, .navbar-light .navbar-nav .no-border > a {
    border-right: none;
}
.navbar-light .navbar-nav .no-border.nav-link:hover {
    background-color: transparent;
}
.navbar-light .navbar-nav .nav-link:hover {
    background-color: #ffffff;
}
.navbar-light .navbar-nav .nav-link.active {
    background-color: #0099dd;
    color: #ffffff;
}
.dropdown-menu-navbar > .dropdown-menu, .dropdown-submenu > .dropdown-menu {
    background-color: #abcf38;
    padding: 0;
}
.dropdown-menu-navbar .dropdown-item {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
    font-size: 13px;
    font-weight: bold;
    padding: .5rem;
}
.dropdown-menu-navbar .dropdown-item:hover {
    color: #16181b;
}
.dropdown-menu-navbar > .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    background-color: none;
    color: #FFFFFF;
}
.navbar-light .navbar-nav .nav-link:hover {
    color: #16181b;
}
.dropdown-toggle.nav-link {
    font-size: 13px;
}
.dropdown-menu-navbar.right > .dropdown-menu {
    right: 0px;
    left: auto;
}
.dropdown-menu-navbar .dropdown-submenu {
    position:relative;
    border-bottom: 1px solid #ffffff;
}
.dropdown-submenu.left > .dropdown-menu {
    top: 4px;
    left: -100%;
    margin-top:-6px;
}
.dropdown-submenu.right > .dropdown-menu {
    top: 4px;
    left: 100%;
    margin-top:-6px;
    min-width: 175px;
}
.nav-logo {
    height: 35px;
    margin-top: 3px;
    margin-left: 3px;
}
.modal-body.dropdown-menu-navbar {
    max-height: 500px;
    overflow-y: auto;
    font-size: 13px;
}
@media only screen and (max-width: 991px) {
    .navbar-light .navbar-nav .nav-link {
        border-right: none;
    }
}

.text-red {
    color: red;
}


.ngdialog-buttons {
    *zoom: 1;
}

.ngdialog-buttons:after {
    content: '';
    display: table;
    clear: both;
}

.ngdialog-buttons .btn {
    margin-left: 10px;
}

.ngdialog-button {
    border: 0;
    border-radius: 3px;
    cursor: pointer;
    float: right;
    font-family: inherit;
    font-size: .9em;
    letter-spacing: .1em;
    line-height: 1em;
    margin: 0 0 0 .5em;
    padding: .75em 2em;

}

.ngdialog-button:focus {
    -webkit-animation: ngdialog-pulse 1.1s infinite;
    animation: ngdialog-pulse 1.1s infinite;
    outline: none;
}

@media (max-width: 568px) {
    .ngdialog-button:focus {
        -webkit-animation: none;
        animation: none;
    }
}

.ngdialog.custom-width .ngdialog-content {
    width: 42%;
}

.ngdialog-button.ngdialog-button-primary {
    background: #3288e6;
    color: #fff;
}

.ngdialog-button.ngdialog-button-secondary {
    background: #e0e0e0;
    color: #777;
}
.modal-backdrop {
    z-index: 9999;
}
.modal {
    z-index: 9999;
}