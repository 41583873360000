#map .ol-zoom .ol-zoom-out {
    margin-top: 204px;
}
#map .ol-zoomslider {
    background-color: transparent;
    top: 2.3em;
}
#map .ol-touch .ol-zoom .ol-zoom-out {
    margin-top: 212px;
}
#map .ol-touch .ol-zoomslider {
    top: 2.75em;
}
#map .ol-zoom-in.ol-has-tooltip:hover [role=tooltip],
#map .ol-zoom-in.ol-has-tooltip:focus [role=tooltip] {
    top: 3px;
}
#map .ol-zoom-out.ol-has-tooltip:hover [role=tooltip],
#map .ol-zoom-out.ol-has-tooltip:focus [role=tooltip] {
    top: 232px;
}
#map .layer-switcher {
    position: absolute;
    top: 1.5em;
    right: 0.5em;
    text-align: left;
}
#map .ol-tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
    font-size: 12px;
}
#map .ol-tooltip-measure {
    opacity: 1;
    font-weight: bold;
}
#map .ol-tooltip-static {
    background-color: #ffcc33;
    color: black;
    border: 1px solid white;
}
#map .ol-tooltip-measure:before,
#map .ol-tooltip-static:before {
    border-top: 6px solid rgba(0, 0, 0, 0.5);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
}
#map .ol-tooltip-static:before {
    border-top-color: #ffcc33;
}
#map .measure-button {
    top: 7px;
    left: 3em;
}
#map .measure-button button.active {
    background-color: red;
}
#map .ol-touch .measure-button {
    top: 80px;
}
#map .ol-popup {
    position: absolute;
    background-color: white;
    -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 280px;
    font-size: 12px;
}
#map .ol-popup:after, .ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
#map .ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}
#map .ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}
#map .ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
}
#map .ol-popup-closer:after {
    content: "✖";
}

#zoomFix {
    position: absolute;
    top: 7px;
    left: 50px;
    background-color: #164a8cb3;
    padding: 7px 10px;
    border-radius: 10px;
    color: #FFF;
}