.display-devices {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 20%;
}

.display-devices .title {
    font-weight: bold;
    font-size: 11px;
    background: #f9f8f6;
    padding: 5px 0 0 5px;
}

.display-devices .results {
    background-color: #EDEAE4;
    font-weight: bold;
    font-size: 11px;
    padding: 5px;
    margin-top: 3px;
}

.display-devices .results a {
    color: red;
    cursor: pointer;
}

.display-devices .device-filters {
    background-color: #f9f8f6;
    float: none;
    padding: 0px 5px;
    font-size: 11px;
    font-weight: bold;
}

.display-devices .device-filters input, .display-devices .device-filters select {
    font-size: 11px;
    height: 28px;
}

.display-devices .content .pagination {
    margin: 0px;
}

.display-devices .content .pagination-sm > li > a {
    padding: 3px 6px;
}

.display-devices .content .pagination > li > a {
    background-color: #29B9F5;
    color: #FFFFFF;
}

.display-devices .content .pagination > li > a:hover,
.display-devices .content .pagination > li.active > a {
    background-color: #2093c3;
    border-color: #2093c3;
}

.display-devices .row {
    margin-top: 2px;
}
