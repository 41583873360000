@media screen and (min-width: 1200px) {

    .div-ufuk-banner{
        background: url(./images/ufukdemo.png);
        height: 43em;
        width: 100%;
        padding: 0;
        background-size: 100%;
        display: block;     
        margin:0 auto;
        background-repeat: no-repeat;
    }
    .row-info{
        height: 60px;
        border-top: 3px solid #eee;
        /* padding-top: 13px;
        margin-bottom: 11px; */

    }
    .sp1{
        color: #867c7c;
    }
    .sp2{
        color: #e95353;
        font-weight: bold;
        font-size: 30px;
    }   
    .sp3{
        font-weight: bold;
        text-decoration: underline;
        font-size: 21px;            
    }           
    .tbl-banner td{
        margin: 0;
        padding: 0;
    }
    table{
        width: 100%;
        height: 100%;
    }
    .left-col{
        margin-left: 16px;
        margin-bottom: 75px;
    }
    .side-col{
        width: 71%;
        text-align: center;
        color: #db5e5e;
        font-weight: bold;
    }       
    .mid-col{
        text-align: center;
        margin-bottom: 170px
    }
    .right-col{
        margin-left: 106px;
        margin-bottom: 44px;
    }
    .s1{
        font-size: 20px;
    }
    .s2{
        float: inline-start;
        text-decoration: underline;
        width: 100%;
    }
    .s3{
        float: inline-start;
        width: 100%;
        padding-left: 7px;
        font-style: italic;
        color: #0e5d83;
        font-family: "Times New Roman", Times, serif;
        line-height: 56px;
    }       
    .s11{
        font-size: 21px;
        line-height: 19px;
    }
    .s12{
        font-size: 20px;
    }
    .s31{
        font-size: 50px;
    }
    .s32{
        font-size: 77px;
    }
    .s33{
        font-size: 20px;
        font-style: normal;
        margin-top: -5px;
    }
    .tbl-banner tr td {
        width: 33%;
    }
    .btop-title{
        text-align: center;
        font-size: 23px;
        font-weight: bold;
        position: relative;
        width: 100%;
        margin-top: 23px;           
    }

    /* The Modal */
.modals {
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    padding: 24px;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
  }
  
  /* Modal Content */
  .modals-content {
    position: relative;
    width: 100%;
    max-width: 620px;
    height: auto;
    margin: auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fefefe;
    border: 1px solid white;
      border-radius: 30px 30px;
  }  
  
  .modals-title {
    margin-bottom: 6px;
    font-size: 24px;
    line-height: 22px;
    font-weight: bold;
    color: #000;
  }
  
  .modals-para {
    margin-top: 6px;
    margin-bottom: 6px;
    font-size: 22px;
    line-height: 20px;
    font-weight: bold;
    color: #000;
  }
  
  
  .modals-img {
    width: 100%;
    max-width: 640px;
    height: auto;
  }
  
  .modals-footer {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 19px;
    font-weight: 500;
    color: #000;
    text-decoration: underline;
  }
  
  @keyframes color-function
  {
      0%
      {
          background-color: #2d9b00;
         
      }
      25%
      {
        background-color: #3cce00;
         
      }
      50%
      {
          background-color: #2d9b00;
         
      }
      75%
      {
        background-color: #3cce00;
         
      }
      100%
      {
        background-color: #2d9b00;
      }
  }
  
  .open-modals {
    position: fixed;
    right: -51px;
    top: 50%;
    width: 140px;
    height: auto;
    border: 1px solid #fff;
    border-radius: 4px 4px 0 0;
    transform: rotate(270deg);
    z-index: 99;
    background-color: #000;
    cursor: pointer;
    animation: color-function 4s infinite;
  }
  
  .open-modals-text-wrapper {
    padding: 10px 6px;
    width: 100%;
    height: auto;
    text-align: center;
  }
  
  .hide-modals {
    position: absolute;
    right: 0;
    left: 136px;
    color: #fff;
    border: 1px solid #fff;
    background-color: #000;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 16px;
    line-height: 18px;
    transform: translateY(-62px);
    text-align: center;
  }
  
  .hide-modals:hover {
    background-color: #2d9b00;
    border: 1px solid #fff;
  }
  
  .open-modals:hover {
    background-color: #2d9b00;
    border: 1px solid #fff;
  }
  
  .open-modals-text {
    color: #fff;
  }
  
  .open-modals-text:hover {
    color: #fff;
  }
  
  .close-modals, .close-modals-3 {
    margin-top: 6px;
    color: #2d9b00;
    font-size: 24px;
    line-height: 20px;
    font-weight: bold;
  }
  
  .close-modals-2, .close-modals-4 {
    position: absolute;
    top: 1px;
    right: 7px;
    font-size: 24px;
    line-height: 22px;
    font-weight: bold;
    color: #000;
  }
  
  .close-modals:hover,
  .close-modals:focus,
  .close-modals-2:hover,
  .close-modals-2:focus,
  .close-modals-3:hover,
  .close-modals-3:focus,
  .close-modals-4:hover,
  .close-modals-4:focus {
    color: #2d9b00;
    text-decoration: none;
    cursor: pointer;
  }
}

@media screen and (max-width: 1200px){
    .div-ufuk-banner {
        display: none;
    }
    .row-info{
        display: none;
    }
}
