.detailert .updating-device {
    position: absolute;
    top: 7px;
    left: 120px;
    display: none;
    z-index: 99999;
}

.detailert {
    position: relative;
    min-height: 57px;
    margin: 2px auto;
    padding: 4px;
    background-color: #f6f6f6;
    font-size: 12px;
}

.detailert a {
    display: block;
}

.detailert .left {
    width: 100px;
    display: block;
    z-index: 1001;
    float: left;
    font-size: 12px;
}

.detailert .right {
    float: right;
    width: 140px;
}

.detailert .right .top {
    margin-bottom: 5px;
}

.detailert .car-driver {
    margin-top: 2px;
}

.detailert .car-driver .icon {
    width: 27px;
}

.detailert .car-driver .driver {
    margin-top: 5px;
}

.detailert .car-driver > a {
    float: left;
    margin-right: 6px;
}

.detailert .name a {
    color: #1267a1;
    font-weight: bold;
}

.detailert .mileage {
    float: right;
    margin-top: 6px;
}

.detailert .live {
    margin-top: 11px;
}

.detailert .live2 {
    margin-top: 7px;
}

.detailert .route {
    font-size: 12px;
    font-weight: bold;
    margin: 4px 10px 0px 11px;
}

.detailert .map-link {
    float: right;
    text-align: center;
    margin: 5px 1px 0 0;
}

.detailert .map-link2 {
    float: right;
    text-align: center;
    margin: 5px 1px 0 0;
}

.detailert .inputs {
    float: left;
    width: 38px;
    margin: 4px 5px 0 13px;
}

.detailert .inputs a {
    display: inline-block;
    width: 25%;
}

.detailert .address {
    font-size: 12px;
    text-align: center;
    padding-top: 3px;
    margin: 0;
    overflow: hidden;
    height: 21px;
    display: none;
}

.detailert .battery {
    float: right;
    margin: 3px 1px 1px 5px;
}

.detailert .ign {
    float: right;
    margin: -2px 5px 0 0;
}

.detailert .signal-date {
    float: right;
}

.detailert .signal-date a {
    text-align: center;
    font-size: 11px;
}

.detailert .right .middle > div {
    float: right;
}

.detailert .chart {
    margin: 4px 6px 0 0;
}

.detailert .speed {
    margin: 7px 5px 0 0;
    min-width: 45px;
    text-align: center;
}

.detailert .gsm-signal {
    margin-right: 5px;
}

.detailert .trip-status {
    font-size: 11px;
}

.detailert .service-alert {
    background: #C7203B;
    float: left;
    padding: 1px 5px 0 5px;
    border-radius: 4px;
}

.detailert .service-alert a {
    font-size: 11px;
    color: #fff;
}

.detailert .service-alert a:hover {
    text-decoration: underline;
}