.user-rights {
    position: relative;
    padding-left: 20px;
    padding-top: 20px;
}

.size-lg {
    font-size: 14px !important;
}

.user-rights .head {
    font-size: 22px;
    margin-bottom: -5px;
}

.user-rights table thead tr th {
    text-align: center;
}

.user-rights .group-box {
    min-width: 250px;
    background-color: #f5f5f5;
    border-radius: 3px;
    margin-right: 15px;
    border: 1px solid #e3e3e3;
    padding: 10px;
}

.user-rights .group-box .list-head {
    font-weight: bold;
}

.user-rights .group-box a, .user-rights .group-box label.anchor {
    color: #08c;
    cursor: pointer;
}

.user-rights .group-box label.anchor:hover {
    background-color: #eee;
}

.user-rights .group-box label.active:hover, .user-rights .group-box a.active:hover {
    background-color: #5DBBEA;
}

.user-rights .group-box a.active,
.user-rights .group-box label.active {
    color: #fff;
    background-color: #08c;
}

.user-rights .subuser-detail {
    float: left;
    min-width: 600px;
}

.user-rights .update-group-name {
    cursor: pointer;
    padding: 8px;
    top: -2px;
}

.user-rights .update-group-name:hover {
    background: #eee;
    border-radius: 5px;
}

.user-rights .selectbox {
    max-height: 17em;
    border: 1px solid #eee;
    border-radius: 4px;
    overflow: auto;
    padding-top: 6px;
    padding-left: 10px;
}

.user-rights .selectbox label {
    display: block;
    font-weight: normal;
    cursor: pointer;
}

.user-rights-filter li {
    padding-top: 4px;
    padding-bottom: 4px;
    list-style: none;
}

.user-rights-filter button, .user-rights-filter input, .user-rights-filter select, .user-rights-filter textarea {
    width: 100%;
}

.user-rights .subuser-device-options label {
    font-weight: normal;
}

.user-rights .red {
    color: #a94442
}

.user-rights .yellow {
    color: #edaa1b
}

.user-rights .green {
    color: #419641
}

.stack {
    display: block;
    margin-bottom: 0.6rem;
}

.sap-options-list {
    max-height: 350px;
    overflow:scroll;
    -webkit-overflow-scrolling: touch;
}

.active-user-device {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}