.reports {
    position: relative;
    padding-left: 15px;
}

.reports .header-img {
    width: 100%;
    max-width: 630px
}

.reports .device-reports {
    float: left;
    width: 255px;
    padding: 15px 20px;
    border: 1px solid #ddd;
    margin: 0 30px 0 0;
}

.reports .export-icons {
    min-width: 250px;
}

.reports .report-icon {
    float: left;
    width: 100px;
    text-align: center;
    font-size: 13px;
}

.reports .filters {
    padding-left: 0;
    font-size: 14px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;

}

.reports .filters .filter-btn {
    margin-bottom: 11px;
}

.reports .filters .input-group-btn {
    padding-top: 4px;
}

.reports .select {
    float: left !important;
    margin: 0 10px 10px -15px;
}

.reports .select-driver {
    margin-bottom: 10px;
}

.reports hr {
    margin: 10px 0;
}

.reports table > tbody > tr > td {
    text-align: center;
    padding: 4px;
    vertical-align: middle;
}

.reports tbody tr.day-total td {
    background: #dff0d8;
    font-weight: bold;
    padding: 3px;
}

.reports tbody tr.day-total:hover td {
    background: #d0e9c6;
}

.reports tbody tr.warning td {
    background: #fcf8e3;
    font-weight: normal;
    padding: 3px;
}

.reports tbody tr.warning:hover td {
    background: #fcf8e3;
}
.reports table {
    font-size: 13px;
}

.reports thead tr th {
    text-align: center;
}

.reports .favorites-box {
    min-width: 150px;
    margin-bottom: 0
}

.reports .trips .glyphicon {
    cursor: pointer;
}

.reports .label {
    display: block;
    padding-top: 3px;
}

.reports .monthly-reports table > tbody > tr > td {
    padding: 7px;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;}

.add-trip > tbody > tr > td {
    padding: 7px 0 2px 10px;
    vertical-align: middle;
}

.add-trip .btn-link {
    padding: 0
}

.ngdialog.ngdialog-theme-default.report-detail .ngdialog-content {
    width: 900px;
}

.report-detail {
    font-size: 13px;
}

.report-detail thead > tr > th {
    text-align: center;
    vertical-align: middle;
}

.report-detail tbody > tr > td {
    text-align: center;
    padding: 7px;
}

.report-detail thead > tr > th.date {
    min-width: 90px;
}

@media (max-width: 400px) {
    /* small phones */
    .reports .report-icon {
        width: 50%;
    }
}

.t-right, .t-right * {
    text-align: right;
}

.no-padding-left {
    padding-left: 0;
}

.reports table>tbody>tr.active>td{
    background: #F5F5FC;
    color: #333;
}

.reports table>tbody>tr.info>td{
    background: #d9edf7;
    color: #333;
}

.reports .download-table-xls-button{
    padding: 1px 7px 2px;
    border-width: 0px;
    color: #007bff;
    text-decoration: none;
    background-color: transparent
}

.report-detail-icon {
    cursor: pointer;
}

.ngdialog-message table>tbody>tr.active>td{
    background: #F5F5FC;
    color: #333;
}

.ngdialog-message table>tbody>tr.danger>td{
    background: #f2dede;
    color: #333;
}

/** route reports favourite match **/
.reports .bg1 {
    background-color: #fff886
}

.reports .bg2 {
    background-color: #ff8686;
}

.ngdialog-buttons .form-group > .badge-danger > a{
    color: #fff;
    background-color: #dc3545;
}

.ngdialog-buttons .form-group > .badge-warning{
    color: #fff;
    background-color: #ffc107;
}

.monthly_report_detail_excel_button {
    position: fixed;
    bottom: 0;
    right: 0;
}

.drivers-list-home {
    position: relative;
    max-width: 100% !important;
    padding-left: 20px;
}

.drivers-list {
    position: relative;
    max-width: 1000px;
}

.drivers-list .head {
    margin-bottom: 0
}

.drivers-list hr {
    margin: 10px 0;
}

.drivers-list table {
    font-size: 13px;
}

.drivers-list table thead tr th {
    text-align: center;
}

.drivers-list table tbody tr td {
    text-align: center;
    vertical-align: middle;
    padding: 5px;
}

.drivers-list table tbody tr td.devices {
    text-align: left;
}

.drivers-list .general {
    min-width: 400px;
    margin-right: 40px;
}

.drivers-list .driver-detail {
    float: left !important;
    width: 950px;
}

.drivers-list .driver-detail .checkbox {
    margin: 0;
}

.drivers-list .working-hours table tbody tr td {
    padding: 0;
}

.drivers-list .btn-link {
    padding: 0;
}

.drivers-list .checkbox b {
    vertical-align: middle;
}

.drivers-list .deactive {
    pointer-events: none;
    opacity: 0.5;
}

.drivers-list .alert {
    padding: 5px 20px;
    margin-bottom: 0px;
    margin-top: 5px;
}

table{
    width: 100%;
    height: 0%;
}

  .cell-with-comment{
    position:relative;
  }
  
  .cell-comment{
    display:none;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 80%;
    left: 50%;
    margin-left: -60px;
    cursor: pointer;
  }
  
  .cell-with-comment:hover span.cell-comment{
    display:block;
  }